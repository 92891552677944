<script>
  if (navigator.userAgent.match(/Android/i)) {
    setTimeout(function(){
      window.location.href = "https://4l993.app.link/ParisRetailWeek2021"
    }, 3000)
  } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    setTimeout(function(){
      window.location.href = "https://4l993.app.link/ParisRetailWeek2021"
    }, 3000)
  } else {
    setTimeout(function(){
      window.location.href = "https://matchmaking.grip.events/parisretailweek2021/login"
    }, 3000)
  }
</script>

<div class="flex items-center justify-center">
  <div class="w-full max-w-lg">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <label
        class="text-green-600 text-center block text-sm font-bold mb-2"
        for="email">
        Thank you
        <br />
        Your password has been successfully reset
        <br />
        You will be redirected to the web application in a few secondes
        <br />
        <br />
        Otherwise, you can click on the button below and close this tab
      </label>
    </div>
    <div class="items-stretch justify-center flex">
      <!-- <a href="https://event.parisretailweek.com/2020" target="_blank">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4
          rounded focus:outline-none focus:shadow-outline m-2 flex-1"
          type="submit">
          Exihitor area
        </button>
      </a>
      <a href="https://badge.parisretailweek.com/accueil.htm" target="_blank">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4
          rounded focus:outline-none focus:shadow-outline m-2 flex-1"
          type="submit">
          Visitor area
        </button>
      </a> -->
      <a href="https://matchmaking.grip.events/parisretailweek2021/login" target="_blank">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4
          rounded focus:outline-none focus:shadow-outline m-2 flex-1"
          type="submit">
          Web application
        </button>
      </a>
    </div>
  </div>
</div>

<div
  id="cxpmClientAccountWidget"
  data-salon="paris_retail_week"
  data-sessionSalon="paris_retail_week_2021"
  data-application="c0d8602de0b4977bc8a2adc6ec129fb2472c0cdc"
  data-language="eng-GB">
  <!-- Nothing here -->
</div>
