<script>
  export let email;
</script>

<div class="flex items-center justify-center">
  <div class="w-full max-w-lg">
    <div class="bg-white mb-4 shadow-md rounded px-8 pt-6 pb-8">
      <label
        class="text-green-600 text-center block text-sm font-bold
        mb-2"
        for="email">
        A regeneration password email was sent to "{email}"
      </label>
    </div>
  </div>
</div>
