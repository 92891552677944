<script>
  const svg = "/images/prw-logo.png";
  let resetPassword = false;
  let setPassword = false;

  if (window.location.href.indexOf("reset-password") > -1) {
    resetPassword = true;
  }

  if (window.location.href.indexOf("first-login") > -1) {
    resetPassword = false;
    setPassword = true;
  }
</script>

<nav
  class="flex items-center justify-center bg-yellow-200 flex-wrap p-6 border-b-2
  border-solid border-grey">
  <div class="flex items-center flex-shrink-0 text-black mr-6">
    <a href="/">
      <svg
        style="background: url({svg}) center center/contain no-repeat;"
        class="fill-current w-20 mr-2"
        width="100"
        height="100" />
      </a>
    <span class="font-semibold text-l tracking-tight">
      28.29.30 SEPT 2021
      <br />
      <span class="text-m font-thin tracking-tight">PARIS EXPO PORTE DE VERSAILLES</span>
    </span>
  </div>
</nav>

{#if resetPassword}
  <h1 class="text-4xl text-gray-700 text-center my-10 tracking-tight">
    Reset your password
  </h1>
{:else if setPassword}
  <h1 class="text-4xl text-gray-700 text-center my-10 tracking-tight">
    Set your password
  </h1>
{:else}
  <h1 class="text-4xl text-gray-700 text-center my-10 tracking-tight">
    Reset your password
  </h1>
{/if}
